import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';

import { Organization } from '../../models/organization.model';
import { UserViewModel } from '../../models/user.model';
import { OrganizationService } from '../../services/organization.service';
import { UserService } from '../../services/user.service';
import { validateForm } from 'src/app/shared/utility/global.functions';

@Component({
  selector: 'app-assign-organization',
  templateUrl: './assign-organization.component.html',
  styleUrls: ['./assign-organization.component.scss']
})
export class AssignOrganizationComponent implements OnInit {
  group!: FormGroup;

  organizations: Organization[] = [];
  user!: UserViewModel;

  loading = true;

  constructor(
    private builder: FormBuilder,
    private organizationService: OrganizationService,
    private userService: UserService,
    private modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
    this.initUser();
  }

  onSubmit() {
    if (this.group.valid) {
      this.addToOrganization(this.data.id);
    } else {
      validateForm(this.group);
    }
  }

  private initForm() {
    this.group = this.builder.group({
      email: [{ value: null, disabled: true }],
      name: [{ value: null, disabled: true }],
      organization: [null, [Validators.required]]
    });
  }

  private async initData() {
    const { data } = await lastValueFrom(this.organizationService.getList());
    this.organizations = data;
  }

  async initUser() {
    const { data } = await lastValueFrom(this.userService.getUser(this.data.id));
    this.group.patchValue({
      email: data.email,
      name: `${data.firstName} ${data.lastName}`
    });
    console.log(data);
    this.user = data;
    this.user._id = this.data.id;
    this.loading = false;
  }

  async addToOrganization(id: string) {
    this.loading = true;
    const organization = this.group.value.organization;
    await lastValueFrom(
      this.userService.assignToOrganization(id, {
        _id: organization._id as string,
        name: organization.name as string
      })
    );
    await lastValueFrom(this.organizationService.setUser(organization._id, this.user));

    this.loading = false;
    this.modalRef.close();
  }
}
