import { Component, Inject, OnInit } from '@angular/core';

import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';

import { PaymentViewModel } from '@features/models/payment.model';
import { ModalService } from '@features/services/modal.service';
import { StripeService } from '@features/services/stripe.service';

@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss']
})
export class PaymentManagementComponent implements OnInit {
  loading = false;
  payment!: PaymentViewModel;
  refundAmount = 0;

  currencyFormatter = (value?: number) => `$ ${value?.toFixed(2)}`;
  currencyParser = (value: string) => value.replace('$ ', '');

  constructor(
    private modalRef: NzModalRef,
    private modalService: ModalService,
    private stripeService: StripeService,
    @Inject(NZ_MODAL_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.payment = this.data.payment;
    this.refundAmount = this.payment.amount - (this.payment.amountRefunded || 0);
  }

  formatStatus(status: 'partial_refund' | 'refunded' | 'requires_capture' | 'succeeded') {
    switch (status) {
      case 'partial_refund':
        return 'Partial refund';

      case 'refunded':
        return 'Refunded';

      case 'requires_capture':
        return 'Uncaptured';

      case 'succeeded':
        return 'Succeeded';

      default:
        return status;
    }
  }

  onSubmit() {
    this.modalService.onConfirm(
      'Are you sure you want to refund this payment?',
      'Refund',
      async () => {
        try {
          this.loading = true;
          await lastValueFrom(this.stripeService.createRefund(this.payment._id, this.refundAmount));
          this.loading = false;
          this.modalRef.close('ok');
          this.modalService.onSuccess('The payment was refunded successfully.', 'Success');
        } catch (error) {
          this.loading = false;
          this.modalRef.close();
          this.modalService.onError('There was a problem trying to refund the payment.', 'Error');
        }
      }
    );
  }

  cancel(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.modalRef.close();
  }
}
