import { Injectable } from '@angular/core';

import * as Papa from 'papaparse';

import { User } from 'src/app/features/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  parseCsv(file: File, organizationId?: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true, // Assumes the first row is the header row
        skipEmptyLines: true,
        complete: results => {
          const users: User[] = results.data.map((row: any) => {
            return {
              firstName: row.firstName,
              lastName: row.lastName,
              phone: row.phone,
              email: row.email,
              status: false,
              active: true,
              notified: false,
              organizationId: organizationId
            };
          });
          resolve(users);
        },
        error: error => {
          reject(error.message);
        }
      });
    });
  }
}
