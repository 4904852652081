export const CATEGORY = {
  endpoint: '/category',
  route: 'categories'
};

export const ENDPOINTS = {
  verify: '/auth/verify'
};

export const ORGANIZATION = {
  endpoint: '/organizations',
  route: 'organizations'
};

export const PAYMENT = {
  endpoint: '/payments'
};

export const PRESENTATION = {
  endpoint: '/presentations'
};

export const SHARED_PRESENTATION = {
  collection: 'shared-presentations',
  endpoint: '/shared'
};

export const PROFILE = {
  endpoint: '/vip-profiles',
  route: 'vip-profiles'
};

export const REFUND = {
  endpoint: '/refunds'
};

export const REPORT = {
  endpoint: '/reports'
}

export const STRIPE = {
  endpoint: '/stripe'
};

export const USER = {
  endpoint: '/vips',
  route: 'users'
};

export const PATTERN = {
  category: /^[a-z0-9]+$/
};
