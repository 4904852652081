import { EventEmitter, Injectable, TemplateRef, Type } from '@angular/core';

import { NzModalService, OnClickCallback } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modal: NzModalService) {}

  onConfirm(content: string, title: string, onOk: EventEmitter<any> | OnClickCallback<any>) {
    this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOnOk: onOk
    });
  }

  onCreate(
    content: Type<any> | TemplateRef<any>,
    config?: { afterClose?: EventEmitter<any>; data?: Partial<any>; width?: string | number }
  ) {
    this.modal.create({
      nzContent: content,
      nzAfterClose: config?.afterClose,
      nzData: config?.data,
      nzFooter: null,
      nzWidth: config?.width || '520px'
    });
  }

  onError(content: string, title: string) {
    this.modal.error({
      nzTitle: title,
      nzContent: content
    });
  }

  onInfo(content: string, title: string) {
    this.modal.info({
      nzTitle: title,
      nzContent: content
    });
  }

  onSuccess(content: string, title: string, config?: { afterClose?: EventEmitter<any> }) {
    this.modal.success({
      nzTitle: title,
      nzContent: content,
      nzAfterClose: config?.afterClose
    });
  }

  onWarning(content: string, title: string) {
    this.modal.warning({
      nzTitle: title,
      nzContent: content
    });
  }
}
