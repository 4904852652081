<h2>Payment Details</h2>

<form nz-form (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label>Amount</nz-form-label>
    <nz-form-control>
      <span> {{ payment.amount | currency }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="payment.amountRefunded">
    <nz-form-label>Amount refunded</nz-form-label>
    <nz-form-control>
      <span> {{ payment.amountRefunded | currency }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Status</nz-form-label>
    <nz-form-control>
      <span> {{ formatStatus(payment.status) }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Payment Method</nz-form-label>
    <nz-form-control>
      <span> {{ payment.card.brand | uppercase }} **** {{ payment.card.last4 }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Description</nz-form-label>
    <nz-form-control>
      <span> {{ payment._id }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Customer Email</nz-form-label>
    <nz-form-control>
      <span> {{ payment.customer.email }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Customer Name</nz-form-label>
    <nz-form-control>
      <span> {{ payment.customer.name }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Customer Phone</nz-form-label>
    <nz-form-control>
      <span> {{ payment.customer.phone }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Date</nz-form-label>
    <nz-form-control>
      <span> {{ payment.created | date: 'MMM d, h:mm a' }} </span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="payment.status === 'partial_refund' || payment.status === 'succeeded'">
    <nz-form-label>Refund amount</nz-form-label>
    <nz-form-control>
      <nz-input-number
        [(ngModel)]="refundAmount"
        name="refundAmount"
        [nzMin]="0.01"
        [nzMax]="this.payment.amount - (this.payment.amountRefunded || 0)"
        [nzStep]="0.01"
        [nzFormatter]="currencyFormatter"
        [nzParser]="currencyParser"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <button
        nz-button
        nzType="primary"
        [disabled]="payment.status === 'requires_capture' || payment.status === 'refunded'"
      >
        Refund
      </button>

      <button
        nz-button
        nzType="primary"
        nzDanger
        style="margin-left: 0.5rem"
        (click)="cancel($event)"
      >
        Cancel
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<app-spinner *ngIf="loading"></app-spinner>
