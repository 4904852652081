<div *ngIf="!dataUploaded">
  <div nz-row nzJustify="center" nzAlign="middle">
    <div nz-col [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
      <h2>Upload CSV</h2>
    </div>
  </div>
  <form nz-form (ngSubmit)="onSubmit()">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired> Upload file </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-upload
          nzType="drag"
          [nzAccept]="'text/csv'"
          [(nzFileList)]="fileList"
          [nzBeforeUpload]="onBeforeUpload"
          nzListType="picture"
        >
          <p class="ant-upload-drag-icon"><i nz-icon nzType="inbox"></i></p>
          <p class="ant-upload-text"></p>
          <p class="ant-upload-hint"></p>
        </nz-upload>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24"> Example CSV </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <a href="assets/test_users.csv" download="example.csv">Download Example CSV</a>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
        <button
          [disabled]="fileList.length === 0"
          nz-button
          nzType="primary"
          style="margin: 0 0.5rem 0.5rem 0"
        >
          Upload
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>

<div *ngIf="dataUploaded">
  <div nz-row nzJustify="center" nzAlign="middle">
    <div nz-col [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
      <h2>CSV Report</h2>
    </div>
  </div>
  <div *ngIf="usersAssignBoolean">
    <div class="header">
      <h3 style="float: left">Users added to organization</h3>
    </div>
    <nz-table #basicTableAssign [nzData]="usersAssign" [nzLoading]="loading" nzSize="small">
      <thead>
        <tr>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTableAssign.data">
          <td>{{ data }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div *ngIf="usersNotAssignBoolean">
    <div class="header">
      <h3 style="float: left">Users already exist</h3>
    </div>
    <nz-table #basicTableNotAssign [nzData]="usersNotAssign" [nzLoading]="loading" nzSize="small">
      <thead>
        <tr>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTableNotAssign.data">
          <td>{{ data }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
