import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OrganizationViewModel } from '../models/organization.model';
import { User, UserViewModel } from '../models/user.model';
import { SubscriberList } from '@features/models/subscriberList.model';
import { Result } from 'src/app/shared/models/result.model';
import { ORGANIZATION } from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get<Result<OrganizationViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}`
    );
  }

  getOrg(id: string | null) {
    return this.http.get<Result<OrganizationViewModel>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}`
    );
  }

  setVipDomain(id: string | null, vipDomain: string) {
    return this.http.patch<Result<void>>(`${environment.api}${ORGANIZATION.endpoint}/${id}`, {
      vipDomain
    });
  }

  getOrgWithVipDomain(vipDomain: string) {
    return this.http.get<Result<string>>(
      `${environment.api}${ORGANIZATION.endpoint}/vip/domain/${vipDomain}`
    );
  }

  setUser(id: string, user: UserViewModel) {
    return this.http.post<Result<void>>(`${environment.api}${ORGANIZATION.endpoint}/${id}/vips`, {
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      notified: user.notified,
      status: user.status,
      active: user.active
    });
  }

  getListUsers(id: string) {
    return this.http.get<Result<UserViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/vips/${id}`
    );
  }

  unassignUser(id: string | undefined, vipId: string) {
    return this.http.delete<Result<void>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}/vips/${vipId}`
    );
  }

  listOrganizationSubscribers(id: string | undefined) {
    return this.http.get<Result<SubscriberList[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}/vips/subscribers`
    );
  }
}
