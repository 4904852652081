import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SetOrganizationDto } from '../models/organization.model';
import { CreateUserResponse, User, UserViewModel } from '../models/user.model';
import { Result } from 'src/app/shared/models/result.model';
import { USER } from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  validateEmail(email: string) {
    return this.http.get<Result<boolean>>(`${environment.api}${USER.endpoint}/valid-email/${email}`);
  }

  getList() {
    return this.http.get<Result<UserViewModel[]>>(`${environment.api}${USER.endpoint}`);
  }

  getUser(id: string) {
    return this.http.get<Result<UserViewModel>>(`${environment.api}${USER.endpoint}/${id}`);
  }

  createUser(user: User) {
    return this.http.post<Result<CreateUserResponse>>(`${environment.api}${USER.endpoint}`, user);
  }

  listForOrganization(organizationId: string) {
    return this.http.get<Result<UserViewModel[]>>(
      `${environment.api}${USER.endpoint}/in-organization/${organizationId}`
    );
  }

  assignToOrganization(userId: string, organization: SetOrganizationDto) {
    return this.http.post<Result<void>>(
      `${environment.api}${USER.endpoint}/${userId}/organizations`,
      { _id: organization._id, name: organization.name }
    );
  }

  changeUserActive(userId: string, isActive: boolean) {
    return this.http.put(`${environment.api}${USER.endpoint}/change-active/${userId}`, {
      isActive
    });
  }

  notifyUser(userId: string) {
    return this.http.put(`${environment.api}${USER.endpoint}/notified/${userId}`, {});
  }

  update(userId: string, updateUser: User) {
    return this.http.put(`${environment.api}${USER.endpoint}/${userId}`, updateUser);
  }
}
