import { FormGroup } from '@angular/forms';

export const validateForm = (group: FormGroup) => {
  Object.values(group.controls).forEach(control => {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
};

export const clearLocalStorage = () => {
  const preservedKeys: string[] = [];
  const keys = Object.keys(localStorage);

  keys.forEach(key => {
    if (preservedKeys.includes(key)) return;
    localStorage.removeItem(key);
  });
};

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

export const toCamelCase = (input: string): string => {
  return input.toLowerCase().replace(/\b(\w)/g, match => match.toUpperCase());
};

export const sortListObject = (
  dataList: any[],
  field: string,
  direction: 'ascending' | 'descending'
) => {
  dataList.sort((a, b) => {
    let fieldType = typeof a[field];
    let x, y;

    switch (fieldType) {
      case 'string':
        x = a[field].toLowerCase();
        y = b[field].toLowerCase();
        break;
      case 'number':
        x = a[field];
        y = b[field];
        break;
    }

    if (x < y) return direction == 'ascending' ? -1 : 1;
    if (x > y) return direction == 'ascending' ? 1 : -1;

    return 0;
  });
};
