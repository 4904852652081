export const environment = {
  production: false,
  api: 'https://vip-admin-api-dev.linkroom.com',
  landing: 'https://fxp-dev.linkroom.com',
  linkroomAdmin: 'https://admin-dev2.linkroom.com',
  linkroomApi: 'https://admindev-api.linkroom.com',
  vipApp: 'https://vip-dev.linkroom.com',
  firebase: {
    projectId: 'linkroom-v2-dev',
    appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
    databaseURL: 'https://linkroom-v2-dev-default-rtdb.firebaseio.com',
    storageBucket: 'linkroom-v2-dev.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
    authDomain: 'linkroom-v2-dev.firebaseapp.com',
    messagingSenderId: '383066649579',
    measurementId: 'G-5HW1Y5YHWW'
  },
  imagekit: {
    publicKey: 'public_HaXtq7gDdr1WTjhEhsMpoKTpp+8=',
    urlEndpoint: 'https://ik.imagekit.io/b152q4ah5/linkroom-dev'
  },
};
