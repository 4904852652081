import { Component, OnDestroy, OnInit } from '@angular/core';

import { lastValueFrom, Subject } from 'rxjs';

import { AuthService } from './core/services/auth.service';
import { OrganizationService } from './features/services/organization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  isCollapsed = false;
  organizationName: string = 'Linkroom';

  organizationId: string | undefined;

  private dispose$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private organizationService: OrganizationService
  ) {}

  ngOnDestroy(): void {
    this.dispose$.next();
    this.dispose$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.authService.organization$.subscribe(async (organizationId: string | null) => {
      if (organizationId !== null) {
        this.organizationId = organizationId as string;
        const { data } = await lastValueFrom(this.organizationService.getOrg(this.organizationId));

        this.organizationName = data.name;
      }
    });
  }
}
