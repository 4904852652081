import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ResellerInterceptor } from './core/interceptors/reseller.interceptor';
import { AssignOrganizationComponent } from './features/components/assign-organization/assign-organization.component';
import { ImportUsersComponent } from './features/components/import-users/import-users.component';
import { PaymentManagementComponent } from './features/components/payment-management/payment-management.component';
import { IconsProviderModule } from './icons-provider.module';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { ImagekitioAngularModule } from 'imagekitio-angular';

registerLocaleData(en);

@NgModule({
  declarations: [
    AssignOrganizationComponent,
    ImportUsersComponent,
    AppComponent,
    PaymentManagementComponent
  ],
  imports: [
    ImagekitioAngularModule.forRoot({
      publicKey: environment.imagekit.publicKey,
      urlEndpoint: environment.imagekit.urlEndpoint
    }),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore())
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResellerInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
