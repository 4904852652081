import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    path: 'auth',
    loadChildren: () => import('./core/components/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./features/components/home/home.module').then(m => m.HomeModule),
    canActivate: [authGuard]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./features/components/user-list/user-list.module').then(m => m.UserListModule),
    canActivate: [authGuard]
  },
  {
    path: 'users/:id',
    loadChildren: () =>
      import('./features/components/user-management/user-management.module').then(
        m => m.UserManagementModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./features/components/organization-list/organization-list.module').then(
        m => m.OrganizationListModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'organization-management',
    loadChildren: () =>
      import('./features/components/organization-management/organization-management.module').then(
        m => m.OrganizationManagementModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'vip-profiles',
    loadChildren: () =>
      import('./features/components/profile-list/profile-list.module').then(
        m => m.ProfileListModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'vip-profiles/:id',
    loadChildren: () =>
      import('./features/components/profile-management/profile-management.module').then(
        m => m.ProfileManagementModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./features/components/payment-list/payment-list.module').then(
        m => m.PaymentListModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./features/components/category-list/category-list.module').then(
        m => m.CategoryListModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'categories/:id',
    loadChildren: () =>
      import('./features/components/category-management/category-management.module').then(
        m => m.CategoryManagementModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'refunds',
    loadChildren: () =>
      import('./features/components/refund-list/refund-list.module').then(m => m.RefundListModule),
    canActivate: [authGuard]
  },
  {
    path: 'refunds/:id',
    loadChildren: () =>
      import('./features/components/refund-management/refund-management.module').then(
        m => m.RefundManagementModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'order-profiles',
    loadChildren: () =>
      import('./features/components/order-profiles/order-profiles.module').then(
        m => m.OrderProfilesModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'subscribers-list',
    loadChildren: () =>
      import('./features/components/subscribers-list/subscribers-list.module').then(
        m => m.SubscribersListModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./features/components/repots/repots.module').then(m => m.RepotsModule),
    canActivate: [authGuard]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
