import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { clearLocalStorage } from '@shared/utility/global.functions';
import { environment } from 'src/environments/environment';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  if (!authService.token) {
    clearLocalStorage();
    document.location.href = authService.redirectUrl || environment.linkroomAdmin;
  }
  return authService.token ? true : false;
};
