import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Result } from '@shared/models/result.model';
import { STRIPE } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(private http: HttpClient) {}

  createRefund(id: string, amount?: number) {
    let params = new HttpParams();
    if (amount) params = params.set('amount', (amount * 100).toString());

    return this.http.post<Result<any>>(
      `${environment.api}${STRIPE.endpoint}/create-refund/${id}`,
      {},
      { params }
    );
  }
}
