import { Component, OnDestroy, OnInit } from '@angular/core';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { lastValueFrom, Subject } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { CsvService } from 'src/app/shared/services/csv.service';
import { getFileExtension } from 'src/app/shared/utility/global.functions';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss']
})
export class ImportUsersComponent implements OnDestroy, OnInit {
  fileList: NzUploadFile[] = [];
  extension: string | undefined;

  private dispose$ = new Subject<void>();

  users: User[] = [];

  usersCreated: string[] = [];
  usersNotCreated: string[] = [];
  usersNotAssign: string[] = [];
  usersAssign: string[] = [];

  adminId: string | undefined;
  organizationId: string | undefined;

  loading = false;

  dataUploaded = false;
  usersCreatedBoolean = false;
  usersNotCreatedBoolean = false;
  usersNotAssignBoolean = false;
  usersAssignBoolean = false;

  constructor(
    public authService: AuthService,
    private csvService: CsvService,
    private userService: UserService
  ) {}

  ngOnDestroy(): void {
    this.dispose$.next();
    this.dispose$.complete();
  }

  ngOnInit(): void {
    this.authService.organization$.subscribe((organizationId: string | null) => {
      if (organizationId !== null) {
        this.organizationId = organizationId as string;
      }
    });
    this.authService.uid$.subscribe((adminId: string | null) => {
      if (adminId !== null) {
        this.adminId = adminId as string;
      }
    });
  }

  onSubmit() {
    this.loading = true;

    Promise.all(
      this.users.map(async user => {
        try {
          const response = await lastValueFrom(this.userService.createUser(user));
          if (response?.data) {
            console.log(response.data);
            this.usersCreated.push(...(response.data.usersCreated ?? []));
            this.usersNotCreated.push(...(response.data.usersNotCreated ?? []));
            this.usersAssign.push(...(response.data.usersAssign ?? []));
            this.usersNotAssign.push(...(response.data.usersNotAssign ?? []));
          } else {
            console.error('Response does not contain the expected data:', response);
          }
        } catch (error) {
          console.error('Error while making the request:', error);
        }
      })
    )
      .then(() => {
        this.usersAssignBoolean = this.usersAssign.length > 0;
        this.usersNotAssignBoolean = this.usersNotAssign.length > 0;
        this.dataUploaded = true;
        this.loading = false;
      })
      .catch(error => {
        console.error('Error during Promise.all:', error);
        this.loading = false;
      });
  }

  onBeforeUpload = (file: NzUploadFile) => {
    const getExtension = `.${getFileExtension(file.name)}`;
    this.extension = getExtension;
    this.fileList = [file];

    this.readFileContent(file);
    this.parseCsvFile(file);

    return false;
  };

  private readFileContent(file: NzUploadFile) {
    const reader = new FileReader();

    reader.onload = event => {
      if (event.target) {
        const content = event.target.result as string;
        console.log('CSV content:', content);
      } else {
        console.error('File reader event target is null.');
      }
    };

    reader.readAsText(file as unknown as File);
  }

  private async parseCsvFile(file: NzUploadFile) {
    try {
      this.users = await this.csvService.parseCsv(file as unknown as File, this.organizationId);
      console.log(this.users);
    } catch (error) {
      console.error('CSV parsing error:', error);
    }
  }
}
