import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Auth } from '../models/auth.model';
import { Result } from 'src/app/shared/models/result.model';
import { ENDPOINTS } from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _organization: string | null = null;
  private _organization$ = new BehaviorSubject<string | null>(null);

  private _redirectUrl?: string;

  private _token: string | null = null;
  private _token$ = new BehaviorSubject<string | null>(null);

  private _uid: string | null = null;
  private _uid$ = new BehaviorSubject<string | null>(null);

  get organization() {
    return this._organization;
  }

  get organization$() {
    return this._organization$.asObservable();
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  get token() {
    return this._token;
  }

  get token$() {
    return this._token$.asObservable();
  }

  get uid() {
    return this._uid;
  }

  get uid$() {
    return this._uid$.asObservable();
  }

  constructor(private http: HttpClient) {
    this._organization = localStorage.getItem('organization');
    this._organization$.next(this._organization);

    this._redirectUrl = localStorage.getItem('redirectUrl') || undefined;

    this._token = localStorage.getItem('token');
    this._token$.next(this._token);

    this._uid = localStorage.getItem('uid');
    this._uid$.next(this._uid);
  }

  init(token: string, redirectUrl?: string, organization?: string) {
    this._token = token;
    this._token$.next(token);
    localStorage.setItem('token', token);

    if (redirectUrl) {
      this._redirectUrl = redirectUrl;
      localStorage.setItem('redirectUrl', redirectUrl);
    }

    if (organization) {
      this._organization = organization;
      this._organization$.next(organization);
      localStorage.setItem('organization', organization);
    } else {
      this._organization = null;
      this._organization$.next(null);
      localStorage.removeItem('organization');
    }
  }

  load(uid: string) {
    this._uid = uid;
    this._uid$.next(uid);
    localStorage.setItem('uid', uid);
  }

  verify() {
    return this.http.post<Result<Auth>>(`${environment.api}${ENDPOINTS.verify}`, {});
  }
}
